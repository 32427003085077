import { GoalName } from '../__generated-gql-types__/globalTypes';

import { isServer } from './constants';

const DEFAULT_LOCALE = 'en-US';

const truncateNamePart = (namePart: string): string => {
  const firstLetter = namePart[0];
  return firstLetter ? `${firstLetter}.` : '';
};

export const formatName = ({
  firstName,
  lastName,
  shouldTruncateLastName = true,
}: {
  firstName: string;
  lastName: string;
  shouldTruncateLastName?: boolean;
}): string => {
  const formattedLastName = shouldTruncateLastName
    ? truncateNamePart(lastName)
    : lastName;

  return `${firstName} ${formattedLastName}`.trim();
};

/**
 * Format rate to include zeros
 * @param {number} rate integer value
 * @returns formatted rate, like '5.00'
 */
export const formatRate = (
  rate: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = minimumFractionDigits,
): string => {
  let locale = DEFAULT_LOCALE;

  // TODO: Migrate to use the next locale (maybe this abstracts the server and client)
  if (!isServer && window?.navigator?.language != null) {
    locale = window.navigator.language;
  }

  const numWithZeroes = rate.toLocaleString(locale, {
    useGrouping: false,
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return numWithZeroes;
};

const GOAL_NAME_DESCRIPTIONS: Record<GoalName, string> = {
  [GoalName.CAREER]: 'Build your Career',
  [GoalName.SCHOOL]: 'Get into School',
  [GoalName.TEST]: 'Take a Test',
};

export const formatGoalDescription = (goal: GoalName): string => {
  return GOAL_NAME_DESCRIPTIONS[goal];
};

const GOAL_NAME_COACHES_LABELS: Record<GoalName, string> = Object.freeze({
  [GoalName.CAREER]: '',
  [GoalName.SCHOOL]: 'Admissions ',
  [GoalName.TEST]: 'Test Prep ',
});

export const formatGoalCoachesLabel = (goal?: GoalName): string => {
  return `${goal ? GOAL_NAME_COACHES_LABELS[goal] : ''}Coaches`;
};
